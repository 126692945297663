import './App.css';
import Avatar from './features/avatar';
import ChartMaker from './sections/chart-maker';
import Hero from './sections/hero';
import { motion, useScroll } from 'framer-motion';
import Residence from './sections/residence';
import Footer from './sections/footer';
import FirstTime from './sections/first-time';
import Overview from './sections/overview';
import Goal from './sections/goal';
import Quotes from './sections/quotes';
import { ScrollIndicator } from './components/scroll-indicator';
import { Cursor } from './components';
import { BrowserView } from 'react-device-detect';
import Games from './sections/games';
import Toys from './sections/toys';
import Movies from './sections/movies';
import Songs from './sections/songs';

function App() {
    return (
        <div className="App">

            <BrowserView>
                <Cursor />
            </BrowserView>
            <ScrollIndicator />
            <Hero />

            <Overview />

            <Movies />
            <Toys />


            {/* <ChartMaker /> */}
            {/* <Residence /> */}

            <Avatar />
            <FirstTime />
            {/* <Songs /> */}
            
            <Games />

            <Goal />

            <Quotes />
            <Footer />
        </div>
    );
}

export default App;
