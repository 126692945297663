import classNames from "classnames";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useCursorHandler } from "../../hooks";
import { useAppStore } from "../../zustand"

export default function Avatar() {
    const { commentary, memoji } = useAppStore(state => state);
    const { mouseEnterHandler, mouseLeaveHandler } = useCursorHandler({ type: "comment" })

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {

        }, 3000)

        return () => clearInterval(interval)
    }, [])

    const cls = 'z-50 text-left text-zinc-500 font-medium px-6 py-6 fixed bottom-24 right-6 rounded-xl border border-zinc-200 shadow-lg bg-gradient-to-b from-zinc-100 to-white'

    return <>
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .4, delay: 1.8 }}
            className={cls}
        >
            👋 你好，这里是设计师陈晨的个人年度报告；
            <br />
            与你分享一下我无趣又充实的 2022；
            {commentary}
        </motion.div>
        <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ y: 240, scale: .1 }}
            animate={{ y: 0, scale: 1 }}
            transition={{ duration: 1.6, ease: "backInOut" }}
            className="w-16 h-16 fixed right-6 bottom-6 bg-zinc-700 rounded-full z-50 overflow-hidden">
            <img src={`/images/memoji/${memoji}.png`} />
        </motion.div>
    </>
}