import { MouseEvent, useCallback } from "react";
import { useAppStore } from "../zustand"

export const useCursorHandler = ({ onMouseEnter, onMouseLeave, type }:
    {
        type: "default" | "comment"
        onMouseEnter?: (e: MouseEvent) => void;
        onMouseLeave?: (e: MouseEvent) => void;
    }) => {
    const { setCursor } = useAppStore();

    const mouseEnterHandler = useCallback((e: MouseEvent) => {
        onMouseEnter && onMouseEnter(e);
        setCursor(type);
    }, [onMouseEnter, setCursor])

    const mouseLeaveHandler = useCallback((e: MouseEvent) => {
        onMouseLeave && onMouseLeave(e);
        setCursor("default");
    }, [onMouseLeave, setCursor])

    return { mouseEnterHandler, mouseLeaveHandler }
}