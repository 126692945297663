import { motion } from "framer-motion";
import { IoArrowDown } from "react-icons/io5";

export default function Hero() {
    return <section className="hero h-screen m-auto w-3/4 flex flex-col justify-center">
        <div className="overflow-hidden">
            <motion.div
                initial={{ y: "100%" }}
                animate={{ y: 0 }}
                transition={{ duration: 1 }}
                className="font-bold tracking-wide text-7xl lg:text-laptop">
                2022
            </motion.div>
        </div>
        <div className="text-left text-4xl">年度报告</div>
        <div className="flex items-center gap-1 animate-bounce">向下滑动 <IoArrowDown className="inline-block" /></div>
    </section>;
}