import { motion } from "framer-motion"
import { IoCloudDownload, IoHeart, IoHome } from "react-icons/io5"
import { Chip, SectionTitle } from "../../components"
import { addiction, covid, tardis, workout } from "../../data"
import { useCursorHandler } from "../../hooks"
import { useAppStore } from "../../zustand"
import Workout from "./workout"

const data = [
    {
        title: '最长连续居家办公',
        data: [{ value: '3个月', suffix: <IoHome /> }],
        description: '在上海经历了三个月的封控',
    },
    {
        title: '单日最长运动时间',
        data: [{ value: '401', suffix: '分钟' }],
        description: '骑车往返潭王路旧高速'
    },
    {
        title: '最大热量消耗', data: [{ value: '3,046', suffix: 'kcal' }],
        description: '相当于 36.7 个苹果的热量',
    },
    {
        title: 'Figma 社区',
        data: [{ value: '17.3k', suffix: <IoCloudDownload /> }, {
            value: 111,
            suffix: <IoHeart className='text-rose-500' />
        }],
        description: '图表插件 Chart Maker 在 Figma 社区获得了 17.3k 的下载量；这是我在设计社区的第一个公开作品。',

    },
    {
        title: '酒精摄入',
        data: [{
            value: 111,
            suffix: 'ml'
        }],
        description: '图表插件 Chart Maker 在 Figma 社区获得了 17.3k 的下载量；这是我在设计社区的第一个公开作品。',
        comment: "戒了"
    }
]


const categories = {
    zelda: {
        name: "塞尔达：旷野之息"
    },
    just_dance: {
        name: "舞力全开"
    },
    dmc5: {
        name: "鬼泣5"
    }
}

export default function Overview() {
    const { comment } = useAppStore(state => state)
    const { mouseEnterHandler, mouseLeaveHandler } = useCursorHandler({ type: "comment" });

    return <section className="section overview">

        <div className="mb-24">
            <SectionTitle>时间与空间的相对维度</SectionTitle>

            <div className='grid sm:grid-cols-2 lg:grid-cols-3'>
                {
                    tardis.map((datum, index) => <Chip
                        {...datum}
                        key={index}
                        onClick={() => { comment(datum.comment || "") }}
                        onMouseEnter={mouseEnterHandler}
                        onMouseLeave={mouseLeaveHandler}
                    />)
                }
                {/* <Chip title='深居简出' data={[{ value: '3个月' }]} description={"在上海经历了三个月的封控"} /> */}
                {/* <Chip title='最大热量消耗' data={[{ value: '2,972', suffix: "kcal" }]} description={"在上海经历了三个月的封控"} /> */}
            </div>

        </div>

        <SectionTitle>新冠相关</SectionTitle>
        {
            covid.map((datum: any, index: number) => (
                <Chip {...datum} key={index} />
            ))
        }

        <SectionTitle>四肢使用情况</SectionTitle>

        <Workout />

        <div className="grid grid-cols-1 lg:grid-cols-3">
            {
                workout.map((datum: any, index: number) => (
                    <Chip {...datum} key={index} />
                ))
            }
        </div>

        <SectionTitle>消费</SectionTitle>

        <SectionTitle>精神食粮</SectionTitle>

        <Chip title="最爱音乐流派" description="" data={[{ value: "Progressive Rock" }]} />
        <Chip title="哪些流派进入了我的歌单" description="" data={[{ value: "Alternate、国语流行" }]} />
        <Chip title="最爱专辑" description="" data={[{ value: <div>In a Glass House <span className="text-md italic font-serif font-normal">by</span> Gentle Giant </div> }]} />

        <SectionTitle>咖啡、酒精和烟草</SectionTitle>

        <div className="grid grid-cols-1 lg:grid-cols-3">
            {
                addiction.map((datum: any, index: number) => (
                    <Chip {...datum} key={index} />
                ))
            }
        </div>

        <SectionTitle>咖啡、酒精和烟草</SectionTitle>
    </section>
}