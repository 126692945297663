import { useScroll } from "framer-motion";
import { useEffect, useRef, WheelEvent } from "react"

export default function Movies() {
    const ref = useRef<HTMLDivElement>(null)
    const { scrollY } = useScroll();
    const topRef = useRef(0)

    useEffect(() => {
        if (ref.current) {
            topRef.current = ref.current.offsetTop
        }

        scrollY.onChange(latest => {
            if (ref.current && ref.current.scrollLeft !== undefined) {
                ref.current.scrollLeft = latest - (topRef.current || 0)
            }
        })
    }, [])

    const onWheel = (e: WheelEvent) => {
        if (ref.current?.scrollLeft) {
            ref.current.scrollLeft += e.deltaY
        }
    }

    return <section
        ref={ref}
        onWheel={onWheel}
        className="w-screen h-screen sticky top-0 overflow-hidden"
    >
        <div className="w-fit overflow-x-scroll h-full flex">
            <div className="w-screen h-full">
                <h1></h1>
            </div>
            <div className="w-screen h-full">

            </div>
            <div className="w-screen h-full"></div>
            <div className="w-screen h-full"></div>
        </div>
    </section>
}