import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { Chip } from "../../components"

const data = [
    {
        name: '一月',
        energy: 0
    },
    {
        name: '二月',
        energy: 0
    },
    {
        name: '三月',
        energy: 0
    },
    {
        name: '四月',
        energy: 0
    },
    {
        name: '五月',
        energy: 0
    },
    {
        name: '六月',
        energy: 0
    },
    {
        name: '七月',
        energy: 558
    },
    {
        name: '八月',
        energy: 17398
    },
    {
        name: '九月',
        energy: 17934
    },
    {
        name: '十月',
        energy: 7656
    },
    {
        name: '十一月',
        energy: 3486
    },
    {
        name: '十二月',
        energy: 230
    },
]



export default function Workout() {
    return <div>
        <div className='w-96 h-96'>
            <Chip
                title="热量消耗"
                data={
                    [
                        {
                            value: <div className="w-72 h-24 text-base">
                                <ResponsiveContainer width={"100%"} height={"100%"}>
                                    <BarChart data={data}>
                                        <Bar dataKey={'energy'} fill="#8884d8" />
                                        <Tooltip contentStyle={{ backgroundColor: 'rgba(255, 255, 255, .5)' }} />
                                        <XAxis dataKey={"name"} fontSize={12} />
                                        <YAxis tickFormatter={v => v / 100 + ""} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        }
                    ]
                }
                description="为什么七月才有数据？因为我七月才买的 Apple Watch。"
            />
        </div>
    </div>
}