import classNames from "classnames";
import { IoArrowBack, IoChatboxEllipses, IoChatboxEllipsesOutline } from "react-icons/io5";
import { useMousePosition } from "../../hooks/use-mouse-position"
import { useAppStore } from "../../zustand";


export function Cursor() {
    const { clientX, clientY } = useMousePosition();
    const { cursor } = useAppStore(state => state)

    const iconCls = classNames("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white transition-all", cursor === "comment" ? "opacity-100" : "opacity-0")

    return <div className="fixed left-0 top-0 right-0 bottom-0 pointer-events-none" style={{ zIndex: 9999 }}>
        <div style={{
            position: "absolute",
            left: clientX,
            top: clientY,
            transform: "translate(-50%, -50%)",
        }}>
            <IoChatboxEllipsesOutline className={iconCls} />
            <svg
                width={100}
                height={100}
                viewBox="0 0 100 100"
            >
                <path
                    id="path"
                    fill="none"
                    d="M25 50a25 25 0 0 1 25 -25h0a25 25 0 0 1 25 25v0a25 25 0 0 1 -25 25h0a25 25 0 0 1 -25 -25z" />
                <text>
                    <textPath href="#path" offset={1} fontSize={12} >
                        Comment
                    </textPath>
                </text>
                <rect
                    id="path"
                    className={cursor === "default" ? "fill-zinc-900" : "fill-red-500"}
                    width={cursor === "default" ? 25 : 50}
                    height={cursor === "default" ? 25 : 50}
                    x={cursor === "default" ? 37.5 : 25}
                    y={cursor === "default" ? 37.5 : 25}
                    rx={100}
                    // fillOpacity={cursor === "default" ? 1 : .3}
                    stroke={'none'}
                    style={{ transition: 'all .2s', transformOrigin: "center center" }}
                />
            </svg>
        </div>
    </div>
}