import classNames from "classnames";
import { useInView } from "framer-motion";
import { ReactNode, useRef } from "react";

export interface SectionTitleProps {
    children: ReactNode;
}

export function SectionTitle({ children }: SectionTitleProps) {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const cls = classNames(
        "overflow-hidden h-fit relative",
        "before:content-[''] before:bg-red-500 before:inline-block before:w-full before:h-full before:absolute before:left-0 before:top-0 before:z-10 before:transition-all before:duration-700 before:border-none",
        isInView ? `before:translate-x-full` : 'before:-translate-x-full'
    )

    return <div
        className="w-fit h-8 relative overflow-hidden mb-16 px-4"
        ref={ref}
    >
        <div className={cls}>
            <h2 className={`section__subtitle ${isInView ? "translate-y-0" : "translate-y-full"} transition-all duration-700 delay-500 font-bold`}>
                {children}
            </h2>
        </div>
    </div>
}