import { Html, useGLTF } from "@react-three/drei"
import { PrimitiveProps } from "@react-three/fiber"

export function MacBook(props: PrimitiveProps) {

    const gltf = useGLTF("/models/macbook.glb")

    return <primitive {...props} object={gltf.scene}>
        {/* <Html
            transform
            as="div"
        >
            <div className="p-24 bg-white absolute rounded-xl" style={{
                padding: 24,
                background: "white"
            }}>
                <h1 className="text-lg" style={{ fontSize: 24 }}>MacBook</h1>
            </div>
        </Html> */}
    </primitive>
}