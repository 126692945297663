import { MouseEvent, ReactNode, useEffect, useRef } from "react";
import { motion, useInView, useScroll } from "framer-motion"

interface ChipProps {
    title: string;
    data: { value: number | string | ReactNode; suffix?: ReactNode }[]
    description: ReactNode;
    delay?: number;
    onClick?: () => void;
    onMouseEnter?: (e: MouseEvent) => void;
    onMouseLeave?: (e: MouseEvent) => void;
}

export function Chip({ title, data, description, delay, onClick, onMouseEnter, onMouseLeave }: ChipProps) {
    const ref = useRef<HTMLDivElement>(null)
    const isInView = useInView(ref, { once: true });

    return <div
        style={{ transform: isInView ? "none" : "translateY(80px)", opacity: isInView ? 1 : 0, transition: "transform .8s ease .3s, opacity .8s ease .3s" }}
        className="chip"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
    >
        <div className="chip__title">{title}</div>
        <div className="flex gap-16">
            {
                data.map(({ value, suffix }, index) => (
                    <div key={index} className="chip__value">
                        {value}
                        {
                            suffix && <span className="chip__value-suffix">{suffix}</span>
                        }
                    </div>
                ))
            }
        </div>
        <div className="chip__description">{description}</div>
    </div>
}