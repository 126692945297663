import create from "zustand"
import { devtools, persist } from "zustand/middleware"
import { Memoji } from "./types";

type AppState = {
    commentary: string;
    comment: (text: string) => void;
    cursor: "default" | "comment";
    setCursor: (type: "default" | "comment") => void;
    memoji: Memoji;
    setMemoji: (memoji: Memoji) => void;
    instances: []
}

export const useAppStore = create<AppState>()(
    devtools(persist(
        (set) => ({
            commentary: '',
            comment: (text: string) => set(state => ({ ...state, commentary: text })),
            cursor: "default",
            setCursor: (type) => set(state => ({ ...state, cursor: type })),
            memoji: "hello",
            setMemoji: (memoji) => set(state => ({ ...state, memoji })),
            instances: []
        })
        , { name: 'app-storage' }
    ))
)