import { motion, useScroll } from "framer-motion";

export function ScrollIndicator() {
    const { scrollYProgress } = useScroll()

    return <motion.div
        className="fixed h-0.5 z-0 top-0 left-0 right-0 bg-zinc-900 origin-left z-50"
        style={{
            scaleX: scrollYProgress
        }} />
}