import { motion } from "framer-motion";
import { ReactNode } from "react";
import { BiCoffee, BiCoffeeTogo } from "react-icons/bi"
import { IoAirplane, IoBicycle, IoBus, IoTrain } from "react-icons/io5";

type Datum = {
    title: string;
    data: { value: ReactNode; suffix?: ReactNode }[];
    description: ReactNode;
    comment?: string;
}

export const books = []

const CoffeeProportion = () => {
    return <div className="flex font-normal pt-5">
        <div className="w-px h-9 relative bg-zinc-300 before:content-['CoffeeLatte(60%)'] before:text-xs before:inline-block before:h-fit before:w-fit before:absolute before:left-1 before:-bottom-1" />
        <BiCoffeeTogo className="text-zinc-900" />
        <BiCoffeeTogo className="text-zinc-900" />
        <BiCoffeeTogo className="text-zinc-900" />
        <BiCoffeeTogo className="text-zinc-900" />
        <BiCoffeeTogo className="text-zinc-900" />
        <BiCoffeeTogo className="text-zinc-900" />
        <div className="w-px h-9 relative bottom-5 bg-zinc-300 before:content-['FlatWhite(30%)'] before:text-xs before:inline-block before:h-fit before:w-fit before:absolute before:left-1 before:-top-1" />
        <BiCoffeeTogo />
        <BiCoffeeTogo />
        <BiCoffeeTogo />
        <div className="w-px h-9 relative bg-zinc-300 before:content-['Espresso(10%)'] before:text-xs before:inline-block before:h-fit before:w-fit before:absolute before:left-1 before:-bottom-1" />
        <BiCoffee />
    </div>
}

export const tardis: Datum[] = [
    {
        title: "长途旅行",
        description: "反向跑毒高手",
        data: [
            {
                value: <div className='flex items-center gap-4'>
                    <div className='text-4xl'>上海</div>

                    <div className='text-xl text-center'>
                        1073km
                        <svg width="80" height="7" viewBox="0 0 80 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <motion.path
                                d="M80 3.5L75 0.613249V6.38675L80 3.5ZM0 4H75.5V3H0V4Z"
                                fill="black"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={{ duration: 4, yoyo: Infinity, ease: "easeInOut" }}
                            />
                        </svg>

                    </div>

                    <div className='text-4xl'>北京<span className='text-zinc-500 text-sm'>(又一次)</span></div>
                </div>
            }
        ]
    },
    {
        title: "旅行总里程",
        description: <div className="flex items-center gap-4">
            <div className="flex gap-1 items-center"><IoAirplane />0</div>
            <div className="flex gap-1 items-center"><IoTrain />6,643 km</div>
            <div className="flex gap-1 items-center"><IoBus />1,112km</div>
        </div>,
        data: [
            {
                value: '7,775', suffix: 'km'
            },
        ],
    },
    {
        title: "在路上的时间",
        description: <div className="flex items-center gap-4">
            <div className="flex gap-1 items-center"><IoAirplane />0</div>
            <div className="flex gap-1 items-center"><IoTrain />6,643 km</div>
            <div className="flex gap-1 items-center"><IoBus />1,112km</div>
        </div>,
        data: [
            {
                value: '26', suffix: 'h'
            },
        ],
    },
    {
        title: "出没于这些地方",
        description: "",
        data: [
            
        ]
    }
]

export const addiction: Datum[] = [
    {
        title: "咖啡消费",
        data: [{
            value: "730+", suffix: "杯"
        }],
        description: <CoffeeProportion />
    },
    {
        title: "酒精消费",
        data: [{
            value: "350", suffix: "ml"
        }],
        description: ""
    },
    {
        title: "烟草消费",
        data: [{
            value: "1", suffix: "根"
        }],
        description: "珍爱生命，远离烟草"
    }
]

export const covid: Datum[] = [
    {
        title: "居家次数",
        data: [{ value: 5, suffix: "次" }],
        description: "最长 3 个月，最短 2 天"
    },
    {
        title: "核酸次数",
        data: [{
            value: "300+", suffix: "次"
        }],
        description: "上半年纠结是去虹梅路做还是去 B 栋；下半年纠结是去 2 号院还是 3 号院。"
    },
    {
        title: "抗原自测",
        data: [
            {
                value: "15+", suffix: "次"
            }
        ],
        description: ""
    }
]

export const workout: Datum[] = [
    {
        title: "单日最长骑行距离",
        data: [{ value: "111.15km", suffix: <IoBicycle className=" animate-tweak" /> }],
        description: "去了潭王路旧高速"
    }
]