import classNames from "classnames";
import { useScroll, useTransform } from "framer-motion"
import { useEffect, useRef } from "react";
import { useMousePosition } from "../../hooks";
import { motion } from "framer-motion";

// const BOX_SHADOW = `0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)`


export default function Games() {
    const { scrollY } = useScroll();
    const ref = useRef<HTMLDivElement>(null);

    const y1 = useTransform(scrollY, [(ref.current?.offsetTop || 0) - 600, (ref.current?.offsetTop || 0) + 1200], [-200, 500])
    const y2 = useTransform(scrollY, [(ref.current?.offsetTop || 0) - 600, (ref.current?.offsetTop || 0) + 1200], [-400, -100])
    const y3 = useTransform(scrollY, [(ref.current?.offsetTop || 0) - 600, (ref.current?.offsetTop || 0) + 1200], [300, 100])

    const { clientX } = useMousePosition();

    const bgBlurred = clientX > window.innerWidth / 2

    useEffect(() => {
    }, [])

    return <section
        className="section h-screen px-80 bg-blue-50"
        ref={ref}
    >
        <motion.div
            style={{
                background: "linear-gradient(180deg, #3D5ED6 0%, #06080F 100%)",
                y: y1,
                transition: "filter .6s",
                scale: .9
            }}
            className={classNames('w-96 h-96 text-left relative z-50 rounded-3xl shadow-md', bgBlurred ? 'blur-sm' : 'blur-0')}
        // onClick={{}}
        >
            <div className="game-card__name">
                <h3 className='game-card__title'>鬼泣5</h3>
                <h3 className='game-card__subtitle'>特别版</h3>
            </div>

            <div className="game-card__time">
                <div className="game-card__time-label">游戏时间</div>
                <div className="game-card__time-value">72 小时</div>
            </div>

            <div>
                <img src={"/images/games/dmc5.png"} className={"absolute bottom-0 -right-16 -z-0"} />
            </div>
        </motion.div>

        <motion.div
            style={{
                background: "linear-gradient(180deg, #FFE9AF 0%, #009DCF 100%)",
                y: y2,
                transition: "filter .6s"
            }}
            className={classNames('w-96 h-96 float-right text-left relative z-50 rounded-3xl shadow-2xl', !bgBlurred ? 'blur-sm' : 'blur-0')}>
            <div className="game-card__name">
                <h3 className='game-card__title'>塞尔达传说</h3>
                <h3 className='game-card__subtitle'>旷野之息</h3>
            </div>

            <div className="game-card__time">
                <div className="game-card__time-label">游戏时间</div>
                <div className="game-card__time-value">72 小时</div>
            </div>

            <div>
                <img src={"/images/games/zelda.png"} className={"absolute bottom-0 -right-32 -z-0"} />
            </div>
        </motion.div>

        <motion.div
            style={{
                background: "linear-gradient(180deg, #FFE9AF 0%, #009DCF 100%)",
                y: y3,
                transition: "filter .6s"
            }}
            className={classNames('w-96 h-96 float-left text-left relative z-50 rounded-3xl shadow-2xl', !bgBlurred ? 'blur-sm' : 'blur-0')}>
            <div className="game-card__name">
                <h3 className='game-card__title'>舞力全开</h3>
                <h3 className='game-card__subtitle'>2922</h3>
            </div>

            <div className="game-card__time">
                <div className="game-card__time-label">游戏时间</div>
                <div className="game-card__time-value">72 小时</div>
            </div>

            <div>
                <img src={"/images/games/rasputin.png"} className={"absolute bottom-0 -right-16 -z-0"} />
            </div>
        </motion.div>

    </section>
}