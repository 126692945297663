import classNames from "classnames";
import { useScroll } from "framer-motion"
import { useEffect, useRef, useState } from "react";
import { useCursorHandler } from "../../hooks";
import { Memoji } from "../../types";
import { useAppStore } from "../../zustand";

const LINE_HEIGHT = {
    default: 40,
    lg: 72
}

type Experience = { title: string, src: string, date?: string, memoji?: Memoji };

const experiences: Experience[] = [
    { title: "在年会上中奖", src: "" },
    { title: "开盲盒", src: "/images/toy-box.jpg" },
    { title: "通关《鬼泣5》血宫", src: "" },
    { title: "宰了莱尼尔", src: "/images/lynel.jpg" },
    { title: "遭遇「医疗事故」", src: "", memoji: "stop" },
    { title: "尝试学习电子琴", src: "" },
    { title: "接触 Web3 项目", src: "", memoji: "working" },
    { title: "吃热干面", src: "" },
    { title: "升职", src: "" },
    { title: "使用全面屏手机", src: "" },
    { title: "落户", src: "" },
    { title: "辞职", src: "", date: "09/22" },
    { title: "骑公路车", src: "/images/bikes.jpg" },
    { title: "当伴郎", src: "/images/bestman.jpg", memoji: "suit" },
    { title: "抽烟", src: "/images/cigaratte.jpg" },
    { title: "读萨特", src: "" },
    { title: "看《甄嬛传》", src: "/images/zhenhuan.jpg" },
    { title: "喝吐了", src: "", memoji: "facepalm" },
    { title: "参加 Hackathon", src: "", memoji: "working" },
    { title: "辦理港澳通行證", src: "" },
    { title: "做年终总结", src: "" },
]

export default function FirstTime() {
    const { setMemoji } = useAppStore(state => state)
    const sectionRef = useRef<HTMLDivElement>(null);
    const { scrollY } = useScroll();
    const [pos, setPos] = useState<"fixed" | "absolute">("absolute");
    const [activeExp, setActiveExp] = useState<Experience | null>(null);
    const { mouseEnterHandler, mouseLeaveHandler } = useCursorHandler({ type: "comment" })
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        const startY = sectionRef.current?.offsetTop;
        const endY = (startY || 0) + (sectionRef.current?.clientHeight || 0);

        return scrollY.onChange(latest => {
            if (startY && latest > startY && latest < endY) {
                setPos("fixed")
            }
            else {
                setPos("absolute")
            }

            setActiveItem(Math.floor((latest + 64 - (sectionRef.current?.offsetTop || 0)) / LINE_HEIGHT.lg))
        })
    }, [])

    const stickyCls = classNames("h-full z-50 font-bold text-zinc-700", pos === "fixed" ? "fixed top-16 m-auto" : "absolute top-16")
    const imgCls = classNames("z-0 max-w-full lg:max-w-3xl", pos === "fixed" ? "fixed top-16 m-auto" : "absolute top-16")

    return <section
        ref={sectionRef}
        className="first-time w-full lg:w-3/4 m-auto overflow-hidden text-left relative flex text-2xl lg:text-7xl"
    >

        <div className="w-1/2 lg:w-1/4 lg:p-16">
            <div className={stickyCls}>
                <div className="m-auto">我第一次</div>
            </div>
        </div>

        <div className="w-1/2 flex flex-col z-10 pt-16 snap-y snap-proximity">
            {experiences.map((exp, index) => (
                <div
                    className="snap-center"
                    // className={`overflow-hidden w-fit relative bg-clip-text bg-blue-500
                    // before:content-[''] before:inline-block before:w-full before:h-full before:bg-zinc-900 before:absolute before:left-0 before:top-0 before:-translate-x-full before:mix-blend-lighten
                    // before:opacity-75
                    // before:transition-all before:duration-500
                    // before:bg-gradient-to-r before:from-cyan-500 before:to-blue-500
                    // hover:before:translate-x-0 z-50`
                    // }
                    onMouseEnter={e => {
                        setActiveExp(exp)
                        mouseEnterHandler(e)
                        if (exp.memoji) { setMemoji(exp.memoji) }
                    }}
                    onMouseLeave={e => {
                        mouseLeaveHandler(e)
                        setMemoji("hello")
                    }}
                    key={index}>
                    <div
                        className={classNames("font-bold transition-all", activeItem === index ? "text-zinc-900 scale-105" : "text-zinc-300")}>
                        {exp.title}
                    </div>
                </div>
            ))}
        </div>

        <div className="absolute top-1/2 lg:relative grayscale lg:grayscale-0">
            <img src={experiences[activeItem]?.src || "/images/bikes.jpg"} className={imgCls} />
            <div>{activeExp?.date}</div>
        </div>
    </section>
}