type Quote = {
    text: string;
    author: string;
}

export default function Quotes() {
    return <section className="section quotes">
        <h1 className="quotes__text">
            The main problem really is the funding.
        </h1>
        <div className="quotes__author">
            ——我的硕导
        </div>
    </section>
}